
export default function getHashParams() {
  const hashParams = {}
  if (typeof window === 'undefined') return hashParams
  var e,
      a = /\+/g,
      r = /([^&;=]+)=?([^&;]*)/g,
      d = function(s) { return decodeURIComponent(s.replace(a, " ")); },
      q = window.location.hash.substring(1);

  while (e = r.exec(q)) { // eslint-disable-line no-cond-assign
    hashParams[d(e[1])] = d(e[2])
  }
  return hashParams
}
