// https://levelup.gitconnected.com/identity-management-with-aws-cognito-in-react-dc166bd799dc
import * as React from 'react'
/** Custom Hooks */

const useAuthHandler = (initialState) => {
  const [auth, setAuth] = React.useState(initialState)
  const setAuthStatus = (userAuth: UserAuth) => {
    window.localStorage.setItem(USER_AUTH_KEY, JSON.stringify(userAuth))
    setAuth(userAuth)
  }
  const setUnauthStatus = () => {
    window.localStorage.removeItem(USER_AUTH_KEY)
    setAuth(DEFAULT_USER_AUTH)
  }
  return {
    auth,
    setAuthStatus,
    setUnauthStatus,
  }
}

const DEFAULT_USER_AUTH = Object.freeze({
  userId: 0,
  idToken: '',
  authenticated: false,
})
const USER_AUTH_KEY = 'UserAuth'

export const getStoredUserAuth = () => {
  const auth = window.localStorage.getItem(USER_AUTH_KEY)
  if (auth) {
    return JSON.parse(auth)
  }
  return DEFAULT_USER_AUTH
}

export const authContext = React.createContext({
  confirmationCodeModal: false,
  auth: DEFAULT_USER_AUTH,
  userTimestamp: 0,
  userId: '',
  username: '',
  userAccountVerified: false,
  setTimestamp: () => {},
  setUserAccountVerified: () => {},
  setUsername: () => {},
  setUserId: () => {},
  setAuthStatus: () => {},
  setUnauthStatus: () => {},
  openConfirmationCodeModal: () => {},
})

const { Provider } = authContext

const AuthProvider = ({ children }) => {
  const [userTimestamp, setTimestamp] = React.useState(0)
  const [userId, setUserId] = React.useState('')
  const [username, setUsername] = React.useState('')
  const [confirmationCodeModal, openConfirmationCodeModal] = React.useState(false)
  const [userAccountVerified, setUserAccountVerified] = React.useState(false)
  const { auth, setAuthStatus, setUnauthStatus } = useAuthHandler(getStoredUserAuth())
  return (
    <Provider
      value={{
        confirmationCodeModal,
        openConfirmationCodeModal,
        auth,
        setAuthStatus,
        setUnauthStatus,
        username,
        userAccountVerified,
        setUserAccountVerified,
        setUsername,
        userId,
        setUserId,
        userTimestamp,
        setTimestamp,
      }}
    >
      {children}
    </Provider>
  )
}

export default AuthProvider
