import React, { useState, useEffect } from 'react'
// import API from 'aws-amplify/API'
import { Link } from 'react-router-dom'
import Container from '../../layouts/Container'
import Column from '../../components/Column'
import Fixed from '../../components/Fixed'
import LoginForm from '../../fragments/LoginForm'
import styles from './Home.css'


export default function Home(props) {
  const { isAuthenticated } = props
  const [ isLoading, setIsLoading ] = useState(true)

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return
      }

      try {
        // const notes = await loadNotes()
        // setNotes(notes)
      } catch (e) {
        alert(e)
      }

      setIsLoading(false)
    }

    onLoad()
  }, [isAuthenticated])

  /*
  function loadNotes() {
    return API.get('notes', '/notes')
  }
  */

  function renderRemindersList(reminders) {
    return [].concat(reminders).map((remind, i) => {
      return (
        <div key={remind.id} className={styles.reminderItem}>
          <Link to={`/reminders/${remind.id}`}>
          <div>
            {remind.title}
            {'Created: ' + new Date(remind.timestamp).toLocaleString()}
          </div>
        </Link>
        </div>
      )
    })
  }

  function renderLander() {
    return (
      <div className={styles.hero}>
        <div className={styles.heroLeft}>
          <h1 className={styles.headline}>
            Just Remind Me
          </h1>
          <p>A simple reminder app</p>
        </div>
        <div className={styles.heroRight}>
          <LoginForm />
        </div>
      </div>
    )
  }

  function renderNotes() {
    const reminders = [{
      id: 'xyz',
      title: 'Remind me of thing',
      url: 'https://google.com',
      timestamp: '2020-01-20T00:29:55.669Z',
    },
    {
      id: 'abc',
      title: 'Other reminder',
      url: 'https://google.com',
      timestamp: '2020-01-20T00:29:55.669Z',
    }]
    return (
      <div>
        <h1>Your Reminders</h1>
        <Link key='new' to='/reminders/new'>
          <h4>Create a new reminder</h4>
        </Link>
        <div>
          {!isLoading && renderRemindersList(reminders)}
        </div>
      </div>
    )
  }

  const title = (isAuthenticated) ? 'Dashboard' : 'Just remind me'
  return (
    <Container title={title} className='hiii'>
      {isAuthenticated ? renderNotes() : renderLander()}
    </Container>
  )
}
