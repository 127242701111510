import React from 'react'
import { Helmet } from 'react-helmet-async'

export default function DefaultLayout(props) {
  const { children, title } = props

  return (
    <div {...props}>
      <Helmet>
        <title>{title || 'Just Remind Me'}</title>
      </Helmet>
      {children}
    </div>
  )
}
