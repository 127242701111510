import React from "react"
import styleGuard from 'style-guard'
import Base from '../../layouts/Base'
import css from './Notfound.css'

const styles = styleGuard(css)

export default function NotFound() {
  return (
    <Base title='Page not found' className={styles.wrapper}>
      <h3 className={styles.heading}>
        Sorry, page not found!
      </h3>
    </Base>
  )
}
