import React, { Component, createRef } from 'react'
import Auth from '@aws-amplify/auth'
import { Link } from 'react-router-dom'
import Container from '../../layouts/Container'
import parseHash from '../../utils/parseHash'
import redactEmail from '../../utils/redactEmail'
import Form from '../../components/Form'
import FieldSet from '../../components/FieldSet'
import Input from '../../components/Input'
import Button from '../../components/Button'
import styles from './LoginReset.css'

export default class ResetPassword extends Component {
  constructor(props) {
    super(props)

    const params = parseHash()

    this.state = {
      params: params,
      email: params.email || '',
      code: params.code,
      codeSent: false,
      confirmed: false,
      isConfirming: false,
      isSendingCode: false,
      pleaseConfirmAccount: false
    }

    this.emailInput = createRef()
  }

  componentDidMount() {
    const { params } = this.state
    // Set email input if redirect has email from Forgot password link
    if (!params.code && params.email) {
      if (this.emailInput && this.emailInput.current) {
        this.emailInput.current.textInput.value = params.email
      }
    }
  }

  handleSendVerificationCode = async (event, data) => {
    event.preventDefault()

    console.log('data', data)

    if (!data || !data.email) {
      alert('Please input your email to continue')
      return false
    }


    this.setState({
      isSendingCode: true,
      email: data.email
    })

    try {
      console.log('Submit forgotPassword')
      const resp = await Auth.forgotPassword(data.email)
      console.log('resp', resp)
      this.setState({ codeSent: true })
    } catch (e) {
      console.log('reset error', e)
      if (e.code ==='UserNotFoundException') {
        console.log('User not found')
        this.setState({ userUnknown: true })
      }
      // thrown when a verification code fails to deliver successfully.
      if (e.code === 'CodeDeliveryFailureException') {

      }
      // Cannot reset password for the user b/c confirmation never happened
      if (e.code === 'InvalidParameterException' && e.message.match(/registered\/verified/)) {
        console.log('testerrr')
        try {
          await Auth.resendSignUp(data.email)
          console.log('Auth.resendSignUp sent')
          this.setState({
            pleaseConfirmAccount: true
          })
        } catch (err) {
          console.log('resendSignUp', err)
          alert('Please check your email to verify your account before reseting password.')
        }
      }
      if (e.code ==='NetworkError') {
        alert('Unable to reset password due to internet connection timeout')
      }
      if (e.code ==='LimitExceededException') {
        alert('Too many resets were requested. Please try again in an hour')
      }
      this.setState({ isSendingCode: false })
    }
  }

  handlePasswordChange = async (event, data) => {
    const { params, email } = this.state
    event.preventDefault()

    console.log('cofirm data', data)

    if (!params.code) {
      alert('Link malformed. Please try resetting password again')
      return false
    }

    if (!data.password) {
      alert('Please input a new password to continue')
      return false
    }

    if (!data.confirm) {
      alert('Please confirm the new password to continue')
      return false
    }

    if (data.password !== data.confirm) {
      alert('Password and confirm password fields do not match. Please update these fields')
      return false
    }

    this.setState({ isConfirming: true })

    try {
      console.log('Submit password reset')
      await Auth.forgotPasswordSubmit(
        email,
        params.code,
        data.password
      )
      this.setState({
        confirmed: true,
        isConfirming: false
      })
      console.log('this.props', this.props)
      this.props.history.push(`/login/#email=${email}&reset=true`)
    } catch (e) {
      console.log('Reset error', e)
      if (e.code === 'UserNotFoundException') {
        if (email) {
          // report incorrect email?
        }
        this.props.history.push(`/login/`)
      }
      this.setState({ isConfirming: false })
    }
  }

  renderRequestCodeForm() {
    const { isSendingCode } = this.state
    return (
      <div>
      <h3>Reset Password</h3>
      <p>Enter your email to request a password reset</p>
      <Form onSubmit={this.handleSendVerificationCode}>
        <FieldSet>
          <label>Email</label>
          <Input
            type='email'
            name='email'
            placeholder='Enter your email address'
            ref={this.emailInput}
          />
        </FieldSet>
        <Button type='submit'>
          {isSendingCode ? 'Resetting' : 'Request Password Reset'}
        </Button>
      </Form>
      </div>
    )
  }

  renderConfirmationForm() {
    const { isConfirming } = this.state
    return (
      <Form onSubmit={this.handlePasswordChange}>
        <FieldSet>
          <label>New Password</label>
          <Input
            type='password'
            name='password'
          />
        </FieldSet>
        <FieldSet>
          <label>Confirm Password</label>
          <Input
            type='password'
            name='confirm'
          />
        </FieldSet>
        <Button
          type='submit'
          >
          {isConfirming ? 'Confirming' : 'Confirm Password Reset'}
        </Button>
      </Form>
    )
  }

  render() {
    const { codeSent, pleaseConfirmAccount, userUnknown, confirmed, params } = this.state
    // Code has been sent
    if (codeSent || userUnknown) {
      if (userUnknown) {
        // report if fishing?
      }
      return (
        <Container title='Password reset instructions sent' className={styles.wrapper}>
          <p>An email has been sent to <strong>{redactEmail(this.state.email)}</strong> with password reset instructions</p>
          <p>Please check your email & click the link included in the email to reset your password.</p>
          <p>If you do not see the email, Please double check your spam folder & contact support.</p>
        </Container>
      )
    }

    // Verification view
    if (params.code && params.email) {
      return (
        <Container title='Enter & confirm new password' className={styles.wrapper}>
          <p>
            <strong>Link successfully verified.</strong>
          </p>
          <p>Please enter & confirm your new password.</p>
          {this.renderConfirmationForm()}
        </Container>
      )
    }

    // Success
    if (confirmed) {
      return (
        <Container title='Success password reset!' className={styles.wrapper}>
          <p>Your password has been reset.</p>
          <p>
            <Link to='/login'>
              Click here to login with your new credentials.
            </Link>
          </p>
        </Container>
      )
    }

    if (pleaseConfirmAccount) {
      return (
        <Container title='Success password reset!' className={styles.wrapper}>
          <p>Your email <strong>{redactEmail(this.state.email)}</strong> has not been confirmed.</p>
          <p>Please check your email & confirm your account before resetting your password</p>
          <p>An email has been sent to <strong>{redactEmail(this.state.email)}</strong> with confirmation instructions</p>
          <p>If you do not see the email, Please double check your spam folder & contact support.</p>
        </Container>
      )
    }

    // Container
    return (
      <Container title='Request password reset' className={styles.wrapper}>
        {this.renderRequestCodeForm()}
      </Container>
    )
  }
}
