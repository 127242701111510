import React, { useState, useEffect, useRef } from 'react'
import Auth from '@aws-amplify/auth'
import { withRouter, Link } from 'react-router-dom'
import { authContext } from '../../utils/auth/AuthContext'
import Form from '../../components/Form'
import FieldSet from '../../components/FieldSet'
import Input from '../../components/Input'
import Button from '../../components/Button'
import useHashParam from '../../utils/useHashParam'
import ForceResetForm from '../ForceResetForm'
import styles from './LoginForm.css'

function LoginForm(props) {
  const emailInput = useRef()
  const passwordInput = useRef()
  const [ params ] = useHashParam()
  const [ isLoading, setIsLoading ] = useState(false)
  // State for force reset
  const [ isForceReset, setForceReset ] = useState(false)
  const [ cognitoUser, setCognitoUser ] = useState()
  const { setAuthStatus } = React.useContext(authContext)

  // 'componentDidMount' effect
  useEffect(() => {
    if (params.email) {
      // TODO Last pass still messing with this
      setTimeout(() => {
        emailInput.current.textInput.value = params.email
        passwordInput.current.textInput.value = ''
        passwordInput.current.focus()
      }, 10)
    } else if (emailInput && emailInput.current) {
      emailInput.current.focus()
    }
  }, [params])

  async function handleLogin(e, data) {
    e.preventDefault()

    if (!data) {
      alert('Please enter form fields')
      emailInput.current.focus()
      return false
    }

    if (!data.email) {
      alert('Please enter email')
      emailInput.current.focus()
      return false
    }

    if (!data.password) {
      alert('Please enter password')
      passwordInput.current.focus()
      return false
    }

    setIsLoading(true)

    try {
      const user = await Auth.signIn(data.email, data.password)
      /* If MFA flow active */
      if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {

      } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        /* If user manually created and a force reset of password is required */
        const { requiredAttributes } = user.challengeParam
        setForceReset(true)
        setCognitoUser(user)
        // ^ This will show force reset password form
      } else if (user.challengeName === 'MFA_SETUP') {
        // Auth.setupTOTP(user);
      } else {
        const currentSession = await Auth.currentSession()
        console.log('auth result', currentSession)
        setAuthStatus({
          userId: currentSession.idToken.payload.sub,
          idToken: currentSession.idToken.jwtToken,
          timestamp: +currentSession.idToken.payload['custom:timestamp'] || 0,
          authenticated: true,
        })
        props.history.push('/')
      }
      // // todo remove props.useHas
    } catch (e) {
      setIsLoading(false)
      console.log('Login Error', e)
      // Email/password wrong
      if (e.code === 'NotAuthorizedException') {
        // report potential bad actor
        alert('Invalid username or password. Please try again')
      }
      if (e.code === 'InvalidParameterException') {
        alert('Invalid username/email or password, please try again')
      }
      // Account requires a password reset, please reset your password before attempting to login
      if (e.code === 'PasswordResetRequiredException') { // 'NewPasswordRequired'
        alert('Password reset required')
        // redirect to forgot/reset
      }
      // User doesnt exist yet
      if (e.code === 'UserNotFoundException') {
        props.history.push(`/signup#email=${data.email}&signup=true`)
        return
      }
      // User not verified in cognito yet
      if (e.code === 'UserNotConfirmedException') {
        alert('Please verify your email')
      }
      // Cognito pools are gone 🔥
      if (e.code ==='ResourceNotFoundException') {
        alert('Something bad happened. Please contact support. Error code foxtrox')
      }
      // Offline
      if (e.code ==='NetworkError') {
        alert('Unable to reset password due to internet connection timeout')
      }
      // Too many requests
      if (e.code ==='LimitExceededException') {
        alert('Too many resets were requested. Please try again in an hour')
      }
    }
  }

  function handleForgotPasswordLink() {
    if (emailInput.current.textInput.value) {
      props.history.push(`/login/reset/#email=${emailInput.current.textInput.value}`)
    } else {
      props.history.push('/login/reset/')
    }
  }

  let newUserMessage
  if (params.newUser) {
    newUserMessage = (
      <div className={styles.introText}>
        Welcome! Please login to your new account
      </div>
    )
  }
  if (params.createRedirect) {
    newUserMessage = (
      <div className={styles.introText}>
        Please login to your account
      </div>
    )
  }

  let resetCompleteMessage
  if (params.reset) {
    resetCompleteMessage = (
      <div className={styles.introText}>
        <b>Password successfully reset.</b> Please login below
      </div>
    )
  }

  let formRender = (
    <Form onSubmit={handleLogin}>
      <FieldSet>
        <label>Email</label>
        <Input
          ref={emailInput}
          name='email'
          type='email'
        />
      </FieldSet>
      <FieldSet>
        <label>Password</label>
        <Input
          ref={passwordInput}
          name='password'
          type='password'
        />
      </FieldSet>
      <div className={styles.forgotPassword} onClick={handleForgotPasswordLink}>
        Forgot password?
      </div>
      <Button
        className={styles.button}
        icon={(isLoading) ? 'refresh' : 'lego'}
        type='submit'
        iconProps={{
          isSpinning: isLoading,
          size: 24,
        }}
      >
        Login
      </Button>
    </Form>
  )

  if (isForceReset) {
    console.log('Force reset show PW')
    formRender = <ForceResetForm user={cognitoUser} />
  }

  return (
    <div className={styles.wrapper}>
      <h3>Login in</h3>
      {newUserMessage}
      {resetCompleteMessage}
      {formRender}
    </div>
  )
}

export default withRouter(LoginForm)
