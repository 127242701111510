import React from 'react'
import Container from '../../layouts/Container'
import Icon from '../../components/Icon'
import Flex from '../../components/Flex'
import Row from '../../components/Row'
import Column from '../../components/Column'
import Fixed from '../../components/Fixed'
import api from '../../utils/api'
import styles from './Test.css'

async function handlePost() {
  api.post('/goodbye').then((d) => {
    console.log('data', d)
  }).catch((e) => {
    console.log('e', e)
  })
}

async function handleGet() {
  api.get('/hello').then((d) => {
    console.log('data', d)
  }).catch((e) => {
    console.log('e', e)
  })
}

export default function TestPage(props) {
  console.log('test page props', props)
  return (
    <Container title='Test Page'>
      <Fixed style={{ right: 20, bottom: 10 }}>
        <button>Hello there</button>
      </Fixed>
      <Row>
        <span>hi</span>
        <span>hi</span>
        <span>hi</span>
        <span>hi</span>
      </Row>
      <Column align='left stretch'>
        <span>col 1</span>
        <span>col 2</span>
        <span>col 3</span>
        <span>col 4</span>
      </Column>
      <Row align='left stretch' style={{overflow: 'auto', height: '80vh'}}>
        <Column className={styles.blue}>
          <span>hi</span>

          <Row style={{ background: 'yellow' }}>
            hi
          </Row>
          <button onClick={handleGet}>
            Raw Get
          </button>
          <button onClick={handlePost}>
            Raw Post
          </button>
        </Column>
        <Column align='left top' className={styles.blue}>
          <Fixed addBumper>
            <button>hahshshsh</button>
          </Fixed>
          <Para />
          <Para />
          <Para />
          <Para />
        </Column>
      </Row>
    </Container>
  )
}

function Para() {
  return (
    <p>lorum Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vitae mauris arcu, eu pretium nisi. Praesent fringilla ornare ullamcorper. Pellentesque diam orci, sodales in blandit ut, placerat quis felis. Vestibulum at sem massa, in tempus nisi. Vivamus ut fermentum odio. Etiam porttitor faucibus volutpat. Vivamus vitae mi ligula, non hendrerit urna. Suspendisse potenti. Quisque eget massa a massa semper mollis.,</p>
  )
}
