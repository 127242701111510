/* eslint-disable no-useless-escape */
import Auth from '@aws-amplify/auth'
import aws4 from 'aws4'
import config from '../_config'

const { apiGateway } = config
const API_BASE = apiGateway.URL

async function getCredentials() {
  let credentials = {}, error
  try {
    credentials = await Auth.currentCredentials()
  } catch (err) {
    error = err
  }
  return [ error, credentials ]
}

function request(method) {
  return async function(url, data = {}) {
    const { host, path } = parseUrl(API_BASE)
    const urlPath = url.replace(API_BASE, '')
    const options = Object.assign({}, {
      method: method,
      host: host,
      path: `${path}${urlPath}`,
      url: `${API_BASE}${urlPath}`,
      service: 'execute-api',
      region: apiGateway.REGION,
    }, data)
    // console.log('options', options)
    /* fetch user creds */
    const [ err, credentials ] = await getCredentials()
    const { accessKeyId, secretAccessKey, sessionToken } = credentials

    if (err) {
      throw new Error(err)
    }

    if (!accessKeyId || !secretAccessKey || !secretAccessKey) {
      throw new Error('Not authenticated')
    }

    /* sign the request */
    const request = aws4.sign(options, {
      accessKeyId,
      secretAccessKey,
      sessionToken
    })
    // console.log('request', request)
    // delete request.headers.Host;

    const response = await fetch(options.url, {
      method: method,
      headers: request.headers,
      body: options.body
    })
    if (response.ok) {
      return await response.json()
    }
    return {
      message: response.statusText
    }
  }
}

function parseUrl(url) {
  const match = url.match(/^(http|https)?(?:[\:\/]*)([a-z0-9\.-]*)(?:\:([0-9]+))?(\/[^?#]*)?(?:\?([^#]*))?(?:#(.*))?$/i)
  return {
    protocol: match[1] || '',
    host: match[2] || '',
    port: match[3] || '',
    path: match[4] || '',
    query: match[5] || '',
    fragment: match[6] || '',
  }
}

export const post = request('POST')
export const get = request('GET')
export const put = request('PUT')
export const Delete = request('DELETE')

export default {
  post: post,
  get: get,
  put: put,
  delete: Delete
}
