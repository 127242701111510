import React from 'react'
import { Route, Switch } from 'react-router-dom'
import AppliedRoute from './components/AppliedRoute'
import AuthenticatedRoute from './components/AuthenticatedRoute'
import UnauthenticatedRoute from './components/UnauthenticatedRoute'

import Home from './pages/Home'
import Login from './pages/Login'
import Signup from './pages/Signup'
import LoginReset from './pages/LoginReset'
import ReminderAdd from './pages/ReminderAdd'
import ReminderView from './pages/ReminderView'
// import Settings from './pages/Settings'
import TestPage from './pages/Test'
import NotFound from './pages/404'

export default function Routes({ appProps }) {
  return (
    <Switch>
      <AppliedRoute path='/' exact component={Home} appProps={appProps} />
      <Route path='/test' component={TestPage} appProps={appProps} />
      <UnauthenticatedRoute path='/login' exact component={Login} appProps={appProps} />
      <UnauthenticatedRoute path='/signup' exact component={Signup} appProps={appProps} />
      <UnauthenticatedRoute path="/login/reset" exact component={LoginReset} appProps={appProps} />
      {/* <AuthenticatedRoute path='/settings' exact component={Settings} appProps={appProps} /> */}
      <AuthenticatedRoute path='/reminders/new' exact component={ReminderAdd} appProps={appProps} />
      <AuthenticatedRoute path='/reminders/:id' exact component={ReminderView} appProps={appProps} />
      {/* Finally, catch all unmatched routes */}
      <Route component={NotFound} />
    </Switch>
  )
}
