import { useState, useEffect } from 'react'
import parseHash from './parseHash'

export default function useHashParam() {
  const initialHash = getHashValue()
  const [hashParams, setInnerValue] = useState(initialHash)
  useEffect(() => {
    const handleHashChange = () => {
      setInnerValue(getHashValue())
    }
    handleHashChange()
    window.addEventListener('hashchange', handleHashChange)
    return () => window.removeEventListener('hashchange', handleHashChange)
  }, [])
  return [hashParams, setInnerValue]
}

function getHashValue() {
  const hashes = parseHash()
  return {
    ...decode(hashes),
    ...{
      _: hashes
    }
  }
}

const DECODE = ['email']

function decode(obj) {
  return Object.keys(obj).reduce((acc, curr) => {
    if (DECODE.includes(curr)) {
      acc[curr] = safeDecode(obj[curr])
      return acc
    }
    acc[curr] = obj[curr]
    return acc
  }, {})
}

function safeDecode(value) {
  try {
    return window.atob(value)
  } catch (err) {
    return value
  }
}
