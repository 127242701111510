import React, { useState, useEffect, useRef } from 'react'
import Auth from '@aws-amplify/auth'
import { withRouter, Link } from 'react-router-dom'
import { authContext } from '../../utils/auth/AuthContext'
import Form from '../../components/Form'
import FieldSet from '../../components/FieldSet'
import Input from '../../components/Input'
import Button from '../../components/Button'

function ForceResetForm(props) {
  const passwordInput = useRef()
  const [ isLoading, setIsLoading ] = useState(false)
  const { setAuthStatus } = React.useContext(authContext)

  async function handleForcedReset(e, data) {
    e.preventDefault()
    console.log('submitted', data)

    if (!data) {
      alert('Please enter form fields')
      return false
    }

    if (!data.password) {
      alert('Please input a new password to continue')
      passwordInput.current.focus()
      return false
    }

    if (!data.confirm) {
      alert('Please confirm the new password to continue')
      return false
    }

    if (data.password !== data.confirm) {
      alert('Password and confirm password fields do not match. Please update these fields')
      return false
    }

    setIsLoading(true)

    try {
      await Auth.completeNewPassword(props.user, data.password)
      const currentSession = await Auth.currentSession()
      console.log('auth result', currentSession)
      setAuthStatus({
        userId: currentSession.idToken.payload.sub,
        idToken: currentSession.idToken.jwtToken,
        timestamp: +currentSession.idToken.payload['custom:timestamp'] || 0,
        authenticated: true,
      })
      props.history.push('/')
      // // todo remove props.useHas
    } catch (e) {
      console.log('Force Reset Error', e)
      alert('Error Resetting password. Please contact support')
      setIsLoading(false)
    }
  }

  return (
    <div>
      <p>A reset of your password is required.</p>
      <p>Please enter a enter password to continue</p>
      <Form onSubmit={handleForcedReset}>
        <FieldSet>
          <label>New Password</label>
          <Input
            type='password'
            name='password'
          />
        </FieldSet>
        <FieldSet>
          <label>Confirm Password</label>
          <Input
            type='password'
            name='confirm'
          />
        </FieldSet>
        <Button
          icon={(isLoading) ? 'refresh' : 'lego'}
          type='submit'
          iconProps={{
            isSpinning: isLoading,
            size: 24,
          }}
        >
          {isLoading ? 'Confirming...' : 'Confirm new password'}
        </Button>
      </Form>
    </div>
  )
}

export default withRouter(ForceResetForm)
