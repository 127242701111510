import React from 'react'
import styleGuard from 'style-guard'
import clsx from 'clsx'
import BaseLayout from '../Base'
import css from './Container.css'

const styles = styleGuard(css)

export default function Container(props) {
  const { className, children } = props
  const classes = clsx(className, styles.wrapper)
  return (
    <BaseLayout {...props} className={classes}>
      {children}
    </BaseLayout>
  )
}
