import React from 'react'
import Container from '../../layouts/Container'
import LoginForm from '../../fragments/LoginForm'
import styles from './Login.css'

export default function Login(props) {
  return (
    <Container title='Login to Just Remind Me' className={styles.wrapper}>
      <LoginForm />
    </Container>
  )
}
