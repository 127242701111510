import React from 'react'
import ReactDOM from 'react-dom'
import Amplify from '@aws-amplify/core'
import { BrowserRouter as Router } from 'react-router-dom'
import AuthContextProvider from './utils/auth/AuthContext'
import { HelmetProvider } from 'react-helmet-async'
import config from './_config'
import App from './App'
import './index.global.css'

/*
// Debug Amplify
window.LOG_LEVEL = 'VERBOSE';
/**/

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
})

ReactDOM.render(
  <AuthContextProvider>
    <HelmetProvider>
      <Router>
        <App />
      </Router>
    </HelmetProvider>
  </AuthContextProvider>,
  document.getElementById('root')
)
