
/* Generated settings file from backend service */
const dev = {
  STRIPE_KEY: 'pk_test_v1amvR35uoCNduJfkqGB8RLD',	// todo update
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://ngz59rcm8a.execute-api.us-east-1.amazonaws.com/prod'
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_jhUXZ7iS4',
    USER_POOL_DOMAIN: 'testing-api-xyz',
    SIGNIN_CALLBACK: 'http://localhost:3000/login/oauth2/code/cognito',
    SIGNOUT_CALLBACK: 'http://localhost:3000/logout',
    APP_CLIENT_ID: '5j6ivro7ersftkoosvfknoq1nu',
    IDENTITY_POOL_ID: 'us-east-1:78022ff1-f816-4de5-a606-b0898de341d7',
  }
}

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? dev
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
