
export default function censorEmail(email) {
  const [ first, end ] = email.split('@')
  return `${censorWord(first || 'na')}@${end || 'com'}`
}

function censorWord(str) {
  const splitAt = Math.floor(str.length / 2)
  const firstChars = str.substring(0, splitAt)
  const other = firstChars + "*".repeat(str.length - splitAt) + str.slice(-1)
  return other
}
