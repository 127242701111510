import intersection from 'lodash.intersection'
import get from 'lodash.get'

const groupPath = 'idToken.payload.cognito:groups'

function getUserGroups(currentSession) {
  return get(currentSession, groupPath, [])
}

export default function check(allowedRoles, props) {
  console.log('props: ', props)
  const groups = getUserGroups(props)
  console.log('current user groups', groups)
  const granted = groups && intersection(groups, allowedRoles).length > 0
  console.log(`check: (${groups}) intersects (${allowedRoles}) = ${granted}`)
  return granted
}

/*
export default function check(rules, role, action, data) {
  const permissions = rules[role];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};
 */
