import React, { useState, useEffect, useRef } from 'react'
import Auth from '@aws-amplify/auth'
import Container from '../../layouts/Container'
import Form from '../../components/Form'
import FieldSet from '../../components/FieldSet'
import Input from '../../components/Input'
import Button from '../../components/Button'
import useHashParam from '../../utils/useHashParam'
import styles from './Signup.css'

export default function Signup(props) {
  console.log('signup hit')
  const [ params ] = useHashParam()
  const emailInput = useRef()
  const passwordInput = useRef()
  const passwordConfirmInput = useRef()
  const [ email, setEmail ] = useState('')
  const [ newUser, setNewUser ] = useState(null)
  const [ isLoading, setIsLoading ] = useState(false)

  // 'componentDidMount' effect
  useEffect(() => {
    console.log('params', params)
    const { code, email, signup, _ } = params
    console.log('email', email)

    // Async func here b/c hooks cant be async
    async function handleConfirmationParam(email, code) {
      try {
        await Auth.confirmSignUp(email, code)
        /* if password in state signIn
          await Auth.signIn(email, password)
        */

        props.history.push(`/login#email=${_.email}&newUser=true`)
      } catch (e) {
        console.log('e', e)
        // TODO add error handling https://github.com/michaeltreyvaud/scrumdiddly-web/blob/2b6b1b04f9d6ae9f5c2576fc17bd0aecfa618e15/src/App/Util/Cognito/confirmErrors.js
        // User already confirmed redirect them
        if (e.message === 'User cannot be confirmed. Current status is CONFIRMED') {
          props.history.push(`/login#email=${_.email}`)
          return
        }

        if (e === 'Username cannot be empty') {
          alert('Missing email')
        }

        // alert(e.message)
        setIsLoading(false)
      }
    }
    if (code && email) {
      console.log('do it')
      handleConfirmationParam(email, code)
      return
    }
    if (email && signup) {
      console.log('hello')
      setTimeout(() => {
        emailInput.current.textInput.value = params.email
        passwordInput.current.textInput.value = ''
        passwordInput.current.focus()
      }, 10)
    }
  }, [])

  // const { setAuthStatus } = React.useContext(authContext)

  async function submitHandler(e, data) {
    e.preventDefault()
    console.log('data', data)

    if (!data || !data.email) {
      alert('Please enter email fields')
      emailInput.current.focus()
      return false
    }
    setEmail(data.email)

    if (!data.password) {
      alert('Please enter password')
      passwordInput.current.focus()
      return false
    }

    if (!data.confirm) {
      alert('Please confirm password')
      passwordConfirmInput.current.focus()
      return false
    }

    if (data.password !== data.confirm) {
      alert('Passwords entered do not match')
      passwordConfirmInput.current.focus()
      return false
    }

    setIsLoading(true)

    try {
      const newUser = await Auth.signUp({
        username: data.email,
        password: data.password,
        attributes: {
          'custom:meta': JSON.stringify({
            // Redirect URL for welcome email
            rurl: window.location.origin,
          }),
        }
      })
      setIsLoading(false)
      setNewUser(newUser)
    } catch (e) {
      console.log('Auth signup error', e)
      // Lamdba failed. Ping admin
      if (e.code === 'InvalidLambdaResponseException') {

      }
      // Lambda failed
      if (e.code === 'UserLambdaValidationException') {

      }
      if (e.code === 'UnexpectedLambdaException') {
        alert('API error. Please contact support')
      }
      if (e.code === 'CodeDeliveryFailureException') {
        // Failed to send code, please check your email and username and try again
      }

      if (e.code === 'UserNotConfirmedException') {
        alert('Please check your email')
      }

      if (e.code === 'InvalidParameterException') {
        // Invalid username/email or password, please try again
        // also could be broken email template
        // 1 validation error detected: Value '<!DOCTYPE html…\p{P}\s*]*\{####\}[\p{L}\p{M}\p{S}\p{N}\p{P}\s*]*"
        if (e.message.match(/validation error detected:/)) {
          console.log('email template issue')
        }
      }
      // Password doesnt meet requirements
      if (e.code === 'InvalidPasswordException') {

      }
      // Throttle from same client. Possible bad actor
      if (e.code === 'TooManyRequestsException') {

      }
      // User already exists, redirect to login
      if (e.code === 'UsernameExistsException') {
        // First try to login in with pw supplied
        // Import from LOGIn await Auth.signIn(data.email, data.password)
        // If this fails, push to login page

        props.history.push(`/login#email=${data.email}&createRedirect=true`)

        // TODO if user not confirmed Auth.resendSignUp(this.state.email); ?

        return
      }
      // alert(e.message)
      setIsLoading(false)
    }
  }

  if (params && params.code && params.email) {
    return (
      <Container title='Verifying...' className={styles.wrapper}>
        Verifying new user account...
      </Container>
    )
  }

  if (newUser) {
    return(
      <Container title='Verify signup' className={styles.wrapper}>
        <div>
          Thank you for signing up. Please click the verification email sent to you at {email}
        </div>
      </Container>
    )
  }

  return (
    <Container title='Signup for Just Remind Me' className={styles.wrapper}>
      <h3>Sign up</h3>
      <Form onSubmit={submitHandler}>
        <FieldSet>
          <label>Email</label>
          <Input
            ref={emailInput}
            type='email'
            name='email'
          />
        </FieldSet>
        <FieldSet>
          <label>Password</label>
          <Input
            ref={passwordInput}
            type='password'
            name='password'
          />
        </FieldSet>
        <FieldSet>
          <label>Confirm Password</label>
          <Input
            ref={passwordConfirmInput}
            type='password'
            name='confirm'
          />
        </FieldSet>
        <Button
          className={styles.button}
          icon={(isLoading) ? 'refresh' : 'lego'}
          type='submit'
          iconProps={{
            isSpinning: isLoading,
            size: 24,
          }}
        >
          Signup
        </Button>
      </Form>
    </Container>
  )
}
