import React from 'react'
import { Link } from 'react-router-dom'
import styleGuard from 'style-guard'
import css from './Navbar.css'

const styles = styleGuard(css)

export default function NavBar(props) {
  const { auth, handleLogout } = props

  let navContent = (
    <>
      <Link to='/test'>
        <span>Test page</span>
      </Link>
      <Link to='/lolololol'>
        <span>404 page</span>
      </Link>
      <Link to='/signup'>
        <span>Signup</span>
      </Link>
      <Link to='/login'>
        <span>Login</span>
      </Link>
    </>
  )

  if (auth.authenticated) {
    navContent = (
      <>
        <Link to='/test'>
          <span>Test page</span>
        </Link>
        <Link to='/lolololol'>
          <span>404 page</span>
        </Link>
        <Link to='/settings'>
          <span>Settings</span>
        </Link>
        <a onClick={handleLogout}>
          Logout
        </a>
      </>
    )
  }

  return (
    <nav className={styles.wrapper}>
      <div className={styles.leftContent}>
        <Link to='/'>Remind Me ⎋</Link>
      </div>
      <div className={styles.rightContent}>
        {navContent}
      </div>
    </nav>
  )
}
