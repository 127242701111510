import React from 'react'
import Container from '../../layouts/Container'

export default function ReminderView(props) {
  return (
    <Container>
      Single reminder view
    </Container>
  )
}
