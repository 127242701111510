import React, { Component, createRef } from 'react'
import debounce from 'lodash.debounce'
import PropTypes from 'prop-types'
import classnames from 'clsx'
import styles from './Fixed.css'

export default class Fixed extends Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    style: PropTypes.object,
    addBumper: PropTypes.bool,
  }
  constructor (props, context) {
    super(props, context)
    this.running = false
    this.hasCoordinates = hasPosition(props.style)
    if (!this.hasCoordinates) {
      this.fixed = createRef()
    }
    // this.bumper = true
  }
  componentDidMount() {
    if (this.hasCoordinates) return
    const { addBumper } = this.props
    // calc initial size
    this.calculateSize()
    // start process
    window.addEventListener('resize', debounce(this.resize, 30))
    if (addBumper) {
      const fixedNode = this.fixed.current
      const height = fixedNode.getBoundingClientRect().height
      const div = document.createElement('div')
      div.style.height = `${height}px`
      div.style.minHeight = `${height}px`
      fixedNode.parentNode.insertBefore(div, fixedNode.nextSibling)
    }
  }
  componentWillUnmount() {
    if (this.hasCoordinates) return
    window.removeEventListener('resize', this.resize)
  }
  calculateSize = () => {
    const fixedNode = this.fixed.current
    if (fixedNode) {
      const height = findParentNodeWithHeight(fixedNode.parentNode)
      // TODO test clientWidth vs offsetWidth
      // const width = this.refs.fixed.parentNode.offsetWidth
      const width = fixedNode.parentNode.clientWidth
      fixedNode.style.width = `${width}px`
      // check if CSS value already there
      fixedNode.style.height = height
    }
  }
  resize = () => {
    if (!this.running) {
      this.running = true
      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(this.calculateSize)
      } else {
        setTimeout(this.calculateSize, 66)
      }
      this.running = false
    }
  }
  render () {
    const { addBumper, children, className, style, ...rest } = this.props
    return (
      <div
        {...rest}
        style={style}
        className={classnames(styles.fixed, className)}
        ref={this.fixed}
      >
        {children}
      </div>
    )
  }
}

function hasPosition(s = {}) {
  return s.hasOwnProperty('top') ||
    s.hasOwnProperty('bottom') ||
    s.hasOwnProperty('left') ||
    s.hasOwnProperty('left')
}

function findParentNodeWithHeight(elem) {
  while (elem) {
    // if target is DOCUMENT_NODE bail
    if (elem.nodeType === 9) {
      return null
    }
    if (elem.style && elem.style.height) {
      // console.log('height', elem.style.height)
      return elem.style.height
    }
    if (elem.offsetHeight) {
      // console.log('offsetHeight', elem.offsetHeight)
      return elem.offsetHeight
    }
    elem = elem.parentNode
  }
  return null
}
