import React from 'react'
import Container from '../../layouts/Container'
import Form from '../../components/Form'
import FieldSet from '../../components/FieldSet'
import Input from '../../components/Input'
import Button from '../../components/Button'
import Icon from '../../components/Icon'
import styles from './ReminderAdd.css'

export default function NewReminder(props) {
  function submitHandler(e, data) {
    console.log('submitted', data)
  }
  return (
    <Container>
      <Form onSubmit={submitHandler}>
        <FieldSet className={styles.fieldSet}>
          <label htmlFor='name'>Name</label>
          <Input
            placeholder='Example name'
            name='name'
            required
          />
        </FieldSet>
        <div className={styles.submit}>
          <Button>
            {'submit'}
          </Button>
        </div>
      </Form>
    </Container>
  )
}
