import React, { useState, useEffect } from 'react'
import Auth from "@aws-amplify/auth"
import { withRouter } from 'react-router-dom'
import { authContext } from './utils/auth/AuthContext'
import check from './utils/auth/check'
import clearAuth from './utils/auth/clearStorage'
import Routes from './Routes'
import NavBar from './fragments/NavBar'
import Icon from './components/Icon'
import styles from './App.css'

/*
export const check = (allowedRoles, props) => {
  console.log('props: ', props)
  const groups = getGroups(props)
  console.log('current user groups', groups)
  const granted = groups && intersection(groups, allowedRoles).length > 0
  console.log(`check: (${groups}) intersects (${allowedRoles}) = ${granted}`)
  return granted
}
*/

function App(props) {
  const [ isLoading, setIsLoading ] = useState(true)
  // old const [isAuthenticated, userHasAuthenticated] = useState(false)
  const { setAuthStatus, setUnauthStatus, auth } = React.useContext(authContext)
  console.log('auth', auth)

  async function handleSignupLogoutRedirect(p, a = {}) {
    const { location } = p
    const { authenticated } = a
    if (location.pathname === '/signup' && location.hash.startsWith('#code') && authenticated) {
      console.log('o shit logout for new account')
      await Auth.signOut()
      setUnauthStatus()
      p.history.push(`${location.pathname}${location.hash}`)
    }
  }

  handleSignupLogoutRedirect(props, auth)

  useEffect(() => {
    // Load icon sprite once on bootup
    Icon.loadSprite()
    // Fetch auth data
    onLoad()
  }, [])

  async function onLoad() {
    console.log('onLoad')
    try {
      // TODO Auth.currentSession hangs forever if offline...
      const currentSession = await Auth.currentSession()
      console.log('Auth.currentSession()', currentSession)
      console.log('current.accessToken.payload', currentSession.accessToken.payload)
      const checker = check(['Author', 'Admin'], currentSession)
      console.log('checker', checker)
      const user = await Auth.currentAuthenticatedUser()
      console.log('user', user)
      // const allowed = check(['Author', 'Admin'], currentSession)
      // console.log('check groups', allowed)


      setAuthStatus({
        userId: currentSession.idToken.payload.sub,
        idToken: currentSession.idToken.jwtToken,
        timestamp: +currentSession.idToken.payload['custom:timestamp'] || 0,
        authenticated: true,
      })

      // old userHasAuthenticated(true)
    } catch (e) {
      console.log('onLoad error', e)
      if (e === 'No current user' || e.code === 'UserNotFoundException') {
        clearAuth()
        setUnauthStatus()
      }
    }

    setIsLoading(false)
  }

  async function handleLogout() {
    await Auth.signOut()

    // New from context
    setUnauthStatus()
    // todo old to remove
    // old userHasAuthenticated(false)

    props.history.push('/login')
  }

  return (
    !isLoading && (
      <div className={styles.app}>
        <NavBar
          auth={auth}
          handleLogout={handleLogout}
        />
        <Routes appProps={{
          isAuthenticated: auth.authenticated,
          // userHasAuthenticated
          }}
        />
      </div>
    )
  )
}

export default withRouter(App)
